import React from 'react';
import { CircleUserRound } from 'lucide-react';

const EndSection = () => {
  return (
    <section className="bg-black text-white w-full p-5">
        <div className="flex flex-col items-center justify-center h-full">
            <strong className="mb-4 text-xs font-semibold tracking-widest uppercase text-white">educator+data analyst+policy maker</strong>
            <div className="text-xl font-thin">
                <a href="https://rogueteacher.me">R<CircleUserRound className="bg-gray-800 text-white rounded-full inline-block translate-y-[-2px]" />GUETEACHER</a>
            </div>
        </div>
    </section>
  );
};

export default EndSection;

