import React from 'react';
import SkillsDashboard from './SkillsDashboard';

const AboutMeSection = () => {
  return (
    <section id="aboutMe" className="relative w-full h-full overflow-hidden bg-center bg-no-repeat bg-cover">
      <div className="container relative w-full px-8 pt-32 pb-24 mx-auto lg:px-4">
        <div className="flex flex-col w-full mb-12 text-left lg:text-center">
          <div className="flex flex-col w-full mb-20 text-left lg:text-center">
            <h2 className="mb-6 font-serif text-4xl font-bold tracking-tighter text-black md:text-7xl lg:text-8xl">
                Experience in education, healthcare and tech, with a focus in government.
            </h2>
            <p className="text-lg text-slate-500 lg:text-center">
                An educator many moons ago, I moved into policy making, healthcare finance and tech for public good. Worst nightmare of my life to leave my childhood dream job. But best thing in life to keep learning.
            </p>
            <SkillsDashboard />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMeSection;