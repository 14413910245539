import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Code, Palette, Calculator, Heading1, SquareKanban, Brush, MapPinned, AudioLines, Receipt, Apple, Pencil, ShoppingBag, Heart, Wallet, Settings, Phone, Globe, Music, Mic, Tv } from 'lucide-react';

const apps = [
  { name: 'Codecademy', icon: Code, description: 'Re-created the Codecademy dashboard webpage to better showcase my credentials as a standalone', link: "https://rogueteacher.me/portfolio/codecademy.html" },
  { name: 'Piet Mondrian', icon: Palette, description: "Re-created Piet Mondrian's 'Composition in Red, Blue & Yellow' entirely in code, with CSS Bootstrap.", link: "https://rogueteacher.me/portfolio/pietmondrian.html"},
  { name: 'Calculator', icon: Calculator, description: 'Designed and coded a simple calculator from scratch.', link: "https://rogueteacher.me/portfolio/calc.html"},
  { name: 'Markdown Editor', icon: Heading1, description: 'Created a Markdown text editor, with open, export and file-drag options.', link: "https://rogueteacher.me/portfolio/markdown.html"},
  { name: 'Kanban', icon: SquareKanban, description: 'Created a dynamic Kanban board to manage my team\'s work assignments.', link: "https://rogueteacher.me/portfolio/kanban.html"},
  { name: 'Children\'s Art Gallery', icon: Brush, description: 'Created a gallery to house my sons\' art projects. Such times are truly precious.', link: "https://rogueteacher.me/portfolio/art-gallery-react/dist/index.html"},
  { name: 'Itinerary Planner', icon: MapPinned, description: 'I was doing up my family\'s Korea 2024 trip, when I realised I could actually create a reuseable app, just for the kicks of it!', link: "https://rogueteacher.me/portfolio/itinerary-planner.html"},
  { name: 'Pendulum Waves', icon: AudioLines, description: 'Replicated a pendulum wave demonstration by Harvard Natural Sciences Lecture Demonstrations.', link: "https://rogueteacher.me/portfolio/pendulumwave.html"},
  { name: 'Expense Tracker', icon: Receipt, description: 'I created this simple expense tracker to review my family expenditure patterns annually. You can use it too!', link: "https://rogueteacher.me/portfolio/expense-tracker.html"},
  { name: 'Food Journal', icon: Apple, description: 'I created a food journal and budget calculator - all part of my quest to eat clean and healthy!', link: "https://rogueteacher.me/portfolio/food-journal.html"},
  { name: 'Blogger', icon: Pencil, description: 'Created my own blog post editor, like Wordpress and Blogger! So I could blog more easily without worrying about code.', link: "https://rogueteacher.me/portfolio/blogger.html"},
];

const AppIcon = ({ name, Icon, description, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a 
      href={`${link}`}
      target="_blank"  
      className="block w-16 mb-4"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="relative flex flex-col items-center">
        <div className={`w-20 h-20 bg-gray-100 rounded-xl flex items-center justify-center mb-1 transition-all duration-200 ease-in-out transform hover:scale-105 ${isHovered ? 'ring-2 ring-black ring-opacity-100' : ''}`}>
          <Icon className="w-12 h-12 text-gray-600" />
        </div>
        <span className="text-xs text-center text-gray-600">{name}</span>
        {isHovered && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 bg-gray-800 text-white text-xs py-2 px-3 rounded-md w-48 shadow-lg z-10">
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-gray-800"></div>
            <p className="font-semibold mb-1">{name}</p>
            <p className="text-gray-300">{description}</p>
          </div>
        )}
      </div>
    </a>
  );
};

const MinimalisticAppGrid = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const appsPerPage = 20;
  const totalPages = Math.ceil(apps.length / appsPerPage);

  const nextPage = () => {
    setCurrentPage((prev) => (prev + 1) % totalPages);
  };

  const prevPage = () => {
    setCurrentPage((prev) => (prev - 1 + totalPages) % totalPages);
  };

  const currentApps = apps.slice(currentPage * appsPerPage, (currentPage + 1) * appsPerPage);

  return (
    <div className="flex items-center justify-center h-full translate-y-[-80px]">
      {/* App Grid */}
      <div className="grid grid-cols-3 md:grid-cols-5 gap-y-6 gap-x-10 h-full">
        {currentApps.map((app, index) => (
          <AppIcon key={index} name={app.name} Icon={app.icon} description={app.description} link={app.link} />
        ))}
      </div>

      {/* Navigation Dots
      <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex space-x-2">
        {Array.from({ length: totalPages }).map((_, index) => (
          <div
            key={index}
            className={`w-1.5 h-1.5 rounded-full ${
              index === currentPage ? 'bg-gray-600' : 'bg-gray-300'
            }`}
          />
        ))}
      </div>*/}

      {/* Page Navigation Buttons */}
      {currentPage > 0 && (
        <button
          onClick={prevPage}
          className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-gray-100 rounded-full p-2 opacity-50 hover:opacity-100 transition-opacity"
        >
          <ChevronLeft className="w-4 h-4 text-gray-600" />
        </button>
      )}
      {currentPage < totalPages - 1 && (
        <button
          onClick={nextPage}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-gray-100 rounded-full p-2 opacity-50 hover:opacity-100 transition-opacity"
        >
          <ChevronRight className="w-4 h-4 text-gray-600" />
        </button>
      )}
    </div>
  );
};

export default MinimalisticAppGrid;