import React from 'react';
import RoundedButton from './RoundedButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IntroSection = () => {
  return (
    <section id="home" className="relative w-full h-screen overflow-hidden bg-center bg-no-repeat bg-cover">
      <div className="container relative w-full px-8 pt-32 pb-24 mx-auto lg:px-4">
        <div className="flex flex-col w-full mb-12 text-left lg:text-center">
          <strong className="mb-4 text-xs font-semibold tracking-widest uppercase text-slate-500">educator+data analyst+policy maker</strong>
          <div className="flex flex-col w-full mb-20 text-left lg:text-center">
            <h2 className="mb-6 font-serif text-4xl font-bold tracking-tighter text-black md:text-7xl lg:text-8xl">
              Hi, I'm Yong Kiat.
              <br></br>
              I believe less is more.
            </h2>
            <p className="text-lg text-slate-500 lg:text-center">
              That's why I kept this website simple. All the good stuff, without any of the fluff.
            </p>
          </div>

          <div className="flex w-full gap-4 lg:justify-center mb-4">
          <a href="mailto:yeoyongkiat@gmail.com" target="_blank"><FontAwesomeIcon className="text-3xl transition-all duration-500 ease-in-out transform hover:text-slate-500" icon="fa-brands fa-google" /></a>
          <a href="https://medium.com/@yeoyongkiat" target="_blank"><FontAwesomeIcon className="text-3xl transition-all duration-500 ease-in-out transform hover:text-slate-500" icon="fa-brands fa-medium" /></a>
          <a href="https://www.youtube.com/yeoyongkiat" target="_blank"><FontAwesomeIcon className="text-3xl transition-all duration-500 ease-in-out transform hover:text-slate-500" icon="fa-brands fa-youtube" /></a>
          <a href="https://www.linkedin.com/in/yongkiat" target="_blank"><FontAwesomeIcon className="text-3xl transition-all duration-500 ease-in-out transform hover:text-slate-500" icon="fa-brands fa-linkedin" /></a>
          </div>

          <div className="flex w-full gap-4 lg:justify-center">
            <a href="https://rogueteacher.me/portfolio/resume.html" target="_blank"><RoundedButton buttonText="Résumé"/></a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IntroSection;