import './App.css';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMedium, faInstagram, faLinkedin, faYoutube, faGoogle } from '@fortawesome/free-brands-svg-icons';
import ScrollToTopButton from './ScrollToTopButton';
import NavigationBar from './NavigationBar';
import IntroSection from './IntroSection';
import AboutMeSection from './AboutMeSection';
import BlogSection from './BlogSection';
import ProjectsSection from './ProjectsSection';
import PortfolioSection from './PortfolioSection';
import EndSection from './EndSection';

library.add(faMedium, faInstagram, faLinkedin, faYoutube, faGoogle);

const handleClick = () => {
  console.log('Button clicked!');
};

function App() {
  return (
    <div>
      <ScrollToTopButton />
      <NavigationBar />
      <IntroSection />
      <AboutMeSection />
      <BlogSection />
      <ProjectsSection />
      <PortfolioSection />
      <EndSection />
      {/* Rest of your app content */}
    </div>
  );
}

export default App;
