import React from 'react';
import RoundedButton from './RoundedButton';
import MinimalisticAppGrid from './MinimalisticAppGrid';

const PortfolioSection = () => {
  return (
    <section id="portfolio" className="relative w-full h-full overflow-hidden bg-center bg-no-repeat bg-cover">
      <div className="container relative w-full px-8 pt-32 pb-24 mx-auto lg:px-4">
        <div className="flex flex-col w-full mb-12 text-left lg:text-center">
          <div className="flex flex-col w-full mb-20 text-left lg:text-center">
            <h2 className="mb-6 font-serif text-4xl font-bold tracking-tighter text-black md:text-7xl lg:text-8xl">
                My app portfolio. 
                <br></br>
                What one can learn and create in his free time.
            </h2>
            <p className="text-lg text-slate-500 lg:text-center">
                Coding brings to life many ideas and enhances communication. In my time at GovTech, I picked up frontend development skillsets through creating and maintaining my own digital portfolio.
            </p>
          </div>
        </div>
        <MinimalisticAppGrid />
      </div>
    </section>
  );
};

export default PortfolioSection;