import React, { useState } from 'react';
import { Menu, CircleUserRound } from 'lucide-react';

const NavigationBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white p-4 shadow-md">
      <div className="container mx-auto flex flex-col md:flex-row md:justify-between md:items-center">
        <div className="flex justify-between items-center">
          <div className="text-xl font-thin">
            <a href="https://rogueteacher.me">R<CircleUserRound className="bg-gray-800 text-white rounded-full inline-block translate-y-[-2px]" />GUETEACHER</a>
          </div>
          <div className="md:hidden">
            <button onClick={toggleMenu} className="focus:outline-none">
              <Menu size={24} />
            </button>
          </div>
        </div>
        <div className={`md:flex md:space-x-8 ${isOpen ? 'block' : 'hidden'} mt-4 md:mt-0`}>
          <a href="https://rogueteacher.me/#home" className="block py-2 md:py-0 hover:text-gray-500">Home</a>
          <a href="https://rogueteacher.me/#aboutMe" className="block py-2 md:py-0 hover:text-gray-500">About Me</a>
          <a href="https://rogueteacher.me/#blog" className="block py-2 md:py-0 hover:text-gray-500">Blog</a>
          <a href="https://rogueteacher.me/#projects" className="block py-2 md:py-0 hover:text-gray-500">Projects</a>
          <a href="https://rogueteacher.me/#portfolio" className="block py-2 md:py-0 hover:text-gray-500">Portfolio</a>
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;