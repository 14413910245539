import React from 'react';
import RoundedButton from './RoundedButton';
import BlogCard from './BlogCard';

const BlogSection = () => {
  return (
    <section id="blog" className="relative w-full h-full overflow-hidden bg-center bg-no-repeat bg-cover">
      <div className="container relative w-full px-8 pt-32 pb-24 mx-auto lg:px-4">
        <div className="flex flex-col w-full mb-12 text-left lg:text-center">
          <div className="flex flex-col w-full mb-20 text-left lg:text-center">
            <h2 className="mb-6 font-serif text-4xl font-bold tracking-tighter text-black md:text-7xl lg:text-8xl">
                I write. 
                <br></br>
                But I also code.
            </h2>
            <p className="text-lg text-slate-500 lg:text-center">
                Mostly musings, for me to stay sharp and an excuse to code. I write algorithms, or at least a meaningful app or visualisation to explain my thoughts.
            </p>
          </div>
          <a href="https://rogueteacher.me/blog.html" className="flex w-full gap-4 lg:justify-center">
            <RoundedButton buttonText="load blog" />
          </a>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
            <BlogCard
              title="Setting Up Your Technical Analysis Toolbox"
              subtitle="Before you run off on your horses to learn about technical analysis, you need access to data. Real market data. I talk basic API calls in this post."
              date="01/01/2022"
              tags={["api"]}
              category="Financial Analytics"
              link="https://rogueteacher.me/blog/tech-analysis-toolbox.html"
            />
            <BlogCard
              title="Designing a Basic Visual Plot"
              subtitle="If you want to understand the market, chances are you'll need a customised visualisation with some tools. We explore that today."
              date="08/01/2024"
              tags={["plotly"]}
              category="Financial Analytics"
              link="https://rogueteacher.me/blog/basic-visual-plot.html"
            />
            <BlogCard
              title="How a Pandemic Works - And What Stops It?"
              subtitle="We explore how to simulate the transmission mechanics of a pandemic on a grid. Written in JavaScript."
              date="07/06/2022"
              tags={["COVID-19", "pandemic"]}
              category="Healthcare"
              link="https://rogueteacher.me/blog/how-a-pandemic-works.html"
            />
            <BlogCard
              title="Blockchain Basics"
              subtitle="I explain what hashing, blocks and blockchains are in this article, with simple JavaScript simulations. Meant to be a quick 101 to understand some of the key concepts in blockchain technology."
              date="08/06/2022"
              tags={["blockchain", "hashing", "blocks"]}
              category="Crypto"
              link="https://rogueteacher.me/projects/blockchain-basics/blockchainAboutMe.html"
            />
            <BlogCard
              title="Reproducing Infographics from The Straits Times"
              subtitle="A short post to document what I learnt about reproducing infographics produced by The Straits Times, a Singapore mainstream media reporting body."
              date="28/06/2022"
              tags={["charts"]}
              category="Data Viz"
              link="https://rogueteacher.me/blog/repro-healthcare-infographics.html"
            />
            <BlogCard
              title="An Introduction to React"
              subtitle="A starter article to begin my journey in React. The what, why and hows of React, at high-level."
              date="07/06/2022"
              tags={["frontend"]}
              category="ReactJS"
              link="https://rogueteacher.me/blog/intro-react.html"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogSection;