import React, { useState } from 'react';

// Create Card Data function (unchanged)
const createCardData = ({
  lineColor = 'border-black',
  title,
  heading,
  date,
  content,
  link
}) => {
  return {
    lineColor,
    title,
    heading,
    date,
    content,
    link
  };
};

// Card component (unchanged)
const Card = ({ cardData }) => {
  const {
    lineColor,
    title,
    heading,
    date,
    content,
    link
  } = cardData;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <a 
    href={`${link}`}
    target="_blank"  
    className={`cursor-pointer bg-white rounded-xl shadow-md p-6 h-full transition-all duration-200 ease-in-out transform hover:scale-105 ${isHovered ? 'ring-2 ring-black ring-opacity-100' : ''}`}
    onMouseEnter={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-center mb-4">
        <span className="text-gray-500 font-semibold">{title}</span>
      </div>
      <div className={`border-t-4 ${lineColor} pt-4`}>
        <h2 className="text-xl font-bold mb-2">{heading}</h2>
        <p className="text-gray-600 mb-4">{date}</p>
        <p className="text-gray-700 text-left">{content}</p>
      </div>
    </a>
  );
};

// Masonry Grid component (unchanged)
const MasonryGrid = ({ children }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 auto-rows-auto">
      {children}
    </div>
  );
};

// App component with sample usage (now with 9 cards)
const ProjectCards = () => {
  const cardDataArray = [
    createCardData({
      title: 'University of Oxford, UK',
      heading: 'Dispersion Interactions Between Poly(p-phenylene vinylene) Polymers',
      date: 'Sep 2011',
      content: 'Despite majoring in Chemistry, I decided to take a leap of faith to specialise in ab-initio and semi-empirical simulation methods in Quantum Physics and Molecular Chemistry. This is the story of how I transited from a degree in Chemistry to a discipline in Physics. Plus, I got to maintain and revise legacy code in Fortran. Heh.',
      link: "https://rogueteacher.me/documents/thesis-paper-2011.pdf"
    }),
    createCardData({
      title: 'Juying Secondary School, SG',
      heading: 'YouTube for Education',
      date: 'Jan 2014',
      content: 'I never imagined I would become a content creator with 4,000 over subscribers. I created videos on Chemistry and Physics to engage my students, which were re-used across Singapore schools for teaching. Glad to be of service!',
      link: "https://www.youtube.com/@YeoYongKiat"
    }),
    createCardData({
      title: 'Ministry of Education, Communications & Engagement',
      heading: 'Social Media Analytics for Government',
      date: 'Jun 2018',
      content: 'I honestly never imagined to be able to bring coding to my workplace. Here, I developed a set of business intelligence tools to inform the ministry\'s public communications strategy. Social media dashboards, click analytics, sentiment analysis - I brought the entire gamut of natural language processing. Also had my first taste of change management and developing a 5-year data analytics strategy.',
      link: "https://rogueteacher.me/projects/data-driven-policy-comms/facebook-analytics.html"
    }),
    createCardData({
      title: 'Ministry of Health, Healthcare Finance',
      heading: 'Long Term Care Subsidy Review',
      date: 'Dec 2021',
      content: 'Well, no demo or portfolio to showcase for this, since everything\'s sensitive data. But I picked up financing skillsets, and understood healthcare financing a lot better in this job. It culminated in me leading a team of analysts to develop affordability thresholds in consultation with CPFB, MOM & MOF. Proud to say that 100% of the policy analysis was executed in Python - again, never thought that coding would come in useful at work.',
      link: ""
    }),
    createCardData({
      title: 'Nanyang Academy of Fine Arts',
      heading: 'Blockchain Basics',
      date: 'Jun 2022',
      content: 'I developed a teaching applet for introducing basic blockchain concepts, which was initially meant for teaching my children. Little did I know that I would end up using it for my talks on cryptocurrency and blockchain at NAFA.',
      link: "https://rogueteacher.me/projects/blockchain-basics/blockchainAboutMe.html"
    }),
    createCardData({
      title: 'GovTech',
      heading: 'Tech Sector Insights',
      date: 'Jul 2022',
      content: 'Whilst at GovTech, I picked up the habit of blogging professionally on Medium. It was a good way to develop tech sector insights, and a convenient resource for thought leadership, product marketing and agency engagement.',
      link: "https://medium.com/@yeoyongkiat"
    }),
  ];

  return (
    <div className="p-4">
      <MasonryGrid>
        {cardDataArray.map((cardData, index) => (
          <Card key={index} cardData={cardData} />
        ))}
      </MasonryGrid>
    </div>
  );
};

export default ProjectCards;