import React from 'react';

const RoundedButton = ({ text, buttonText, ...props }) => {
  return (
      <button
        className="inline-flex items-center px-8 py-1 text-white transition-all duration-500 ease-in-out transform bg-black border-2 border-black rounded-lg md:mb-2 lg:mb-0 hover:border-white hover:bg-slate-500 focus:ring-2 ring-offset-current ring-offset-2"
        {...props}
      >
        {buttonText}
      </button>
  );
};

export default RoundedButton;