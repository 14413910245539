import React, { useState } from 'react';
import { Calendar, Tag, Folder} from 'lucide-react';

const BlogCard = ({ title, subtitle, date, tags, category, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a 
      href={`${link}`}
      target="_blank" 
      className={`bg-white rounded-lg shadow-md p-6 max-w-md transition-all duration-200 ease-in-out transform hover:scale-105 ${isHovered ? 'ring-2 ring-black ring-opacity-100' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      
      <div className="flex items-center mb-2">
        <div className="flex items-center text-gray-500">
          <Calendar size={16} className="mr-1" />
          <span>{date}</span>
        </div>
      </div>

      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center text-black">
          <Folder size={16} className="mr-1" />
          <span>{category}</span>
        </div>
      </div>

      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map((tag, index) => (
          <span key={index} className="bg-gray-200 text-gray-700 px-2 py-1 rounded-full text-sm flex items-center">
            <Tag size={12} className="mr-1" />
            {tag}
          </span>
        ))}
      </div>

      <h2 className="text-2xl font-bold mb-2">{title}</h2>
      <p className="text-gray-600 mb-4">{subtitle}</p>
      
      
    
    </a>
  );
};

export default BlogCard;