import React from 'react';
import { Book, Code, GraduationCap, Building2, User } from 'lucide-react';

const SkillSection = ({ icon, title, skills, learning }) => (
  <div className="bg-gray-100 p-4 rounded-lg">
    <div className="flex items-center mb-2">
      {icon}
      <h2 className="text-xl font-bold ml-2">{title}</h2>
    </div>
    <p className="text-sm text-gray-600 text-left">{skills}</p>
    {learning && (
      <div className="mt-2 text-left">
        <strong className="text-sm">Now Learning:</strong>
        <p className="text-sm text-gray-600 text-left">{learning}</p>
      </div>
    )}
  </div>
);

const SkillsDashboard = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4">
        <SkillSection
          title="Pedagogy & School"
          skills="Coaching, facilitation & engagement, education psychology, large-scale & 1-1 pedagogy, masterclass & workshopping."
          learning="Agile, Scrum & Project Management"
        />
        <SkillSection
          title="Data & Coding"
          skills="Analytics (R, Python, Fortran, Excel), Visualisation & Design (Tableau, QlikSense, Plotly), Web Dev (JS, CSS, HTML), Data Methods (NLP, ML), Financial & Affordability Analysis"
          learning="React, PWA, D3.js, Solidity"
        />
        <SkillSection
          title="Public Service"
          skills="Media engagement, public comms, whole-of-government coordination, policy planning & analysis, financial & treasury operations, IT system mapping"
          learning="Government Digital Services"
        />
      </div>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <SkillSection
          title="Now Reading"
          skills="The Road to React, by Robin Wieruch"
        />
        <SkillSection
          title="Now Pursuing"
          skills="Product Management Program, Stanford University"
        />
      </div>
    </div>
  );
};

export default SkillsDashboard;