import React from 'react';
import RoundedButton from './RoundedButton';
import ProjectCards from './ProjectCards';

const ProjectsSection = () => {
  return (
    <section id="projects" className="relative w-full h-full overflow-hidden bg-center bg-no-repeat bg-cover">
      <div className="container relative w-full px-8 pt-32 pb-24 mx-auto lg:px-4">
        <div className="flex flex-col w-full mb-12 text-left lg:text-center">
          <div className="flex flex-col w-full mb-20 text-left lg:text-center">
            <h2 className="mb-6 font-serif text-4xl font-bold tracking-tighter text-black md:text-7xl lg:text-8xl">
                Work projects.
                <br></br>
                I document my work.
            </h2>
            <p className="text-lg text-slate-500 lg:text-center">
                Things that keep me alive and busy. Work was made by God for man - or was it that man was made by God for work?
            </p>
          </div>
          <a href="https://rogueteacher.me/projects.html" className="flex w-full gap-4 lg:justify-center">
            <RoundedButton buttonText="load projects" />
          </a>
          <ProjectCards />
        </div>
      </div>
    </section>
  );
};

export default ProjectsSection;